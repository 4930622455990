<template>
  <div class="servicios mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Servicios
          </v-card-title>
          <v-card-subtitle>
            Desde este apartado podrás modificar el valor de tus servicios.
            <br />
            <strong>
              El IVA solo se tiene en cuenta en el régimen común
            </strong>
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-data-table
                  :headers="headers"
                  :items="listaServicios"
                  :footer-props="{ itemsPerPageText: 'Servicios' }"
                  :search="search"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-col cols="4" class="px-0">
                        <v-text-field
                          outlined
                          rounded
                          dense
                          append-icon="mdi-magnify"
                          label="Buscar"
                          class="mt-6"
                          v-model="search"
                        ></v-text-field>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-btn dark color="black" @click="download()">
                        <v-icon left>mdi-download</v-icon>
                        Descargar precios
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.duracion="{ item }">
                    <span>
                      {{ convert_minutes(item.duracion) }}
                    </span>
                  </template>
                  <template v-slot:item.valor="{ item }">
                    <div
                      class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light 
                      v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined 
                      v-text-field--rounded"
                    >
                      <div class="v-input__control">
                        <div class="v-input__slot">
                          <fieldset aria-hidden="true">
                            <legend style="width: 0px;"><span>​</span></legend>
                          </fieldset>
                          <div class="v-text-field__slot">
                            <input
                              id="input-386"
                              type="number"
                              v-model.number="item.valor"
                              @change="updateItem(item)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:item.valor_sin="{ item }">
                    <span> {{ item.valor_sin | currency }} </span>
                  </template>
                  <template v-slot:item.iva="{ item }">
                    <div
                      class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light 
                      v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined 
                      v-text-field--rounded mt-2"
                    >
                      <div class="v-input__control">
                        <div class="v-input__slot">
                          <fieldset aria-hidden="true">
                            <legend style="width: 0px;"><span>​</span></legend>
                          </fieldset>
                          <div class="v-text-field__slot">
                            <input
                              id="input-386"
                              type="number"
                              v-model.number="item.iva"
                              @change="updateItem(item)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
import XLSX from "xlsx";
export default {
  name: "servicios",
  data: () => ({
    servicios: [],
    search: "",
    headers: [
      {
        text: "Código",
        value: "codigo",
        align: "start",
      },
      {
        text: "Servicio",
        value: "servicio",
      },
      {
        text: "Duración",
        value: "duracion",
      },
      {
        text: "Valor",
        value: "valor",
      },
      {
        text: "Valor sin IVA",
        value: "valor_sin",
      },
      {
        text: "IVA",
        value: "iva",
      },
    ],
  }),
  methods: {
    loadServicios() {
      const body = {
        route: "/servicios_activos",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.servicios = response.data.data;
        }
      });
    },
    convert_minutes(time) {
      let hour = Math.floor(Math.floor(time) / 60);
      let minutes = Math.floor(time) % 60;
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      let value;
      if (hour == 0) {
        value = `${minutes} Min`;
      } else {
        value = `${hour}:${minutes} Hr`;
      }
      return value;
    },
    updateItem(item) {
      item.valor = item.valor == "" ? 0 : item.valor;
      item.iva = item.iva == "" ? 0 : item.iva;
      const body = {
        route: "/update_servicios_valor",
        data: {
          id: item.id_activo,
          valor: item.valor,
          iva: item.iva,
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            let alert = {
              alert: true,
              color: "success",
              text: response.data.data,
            };
            item.valor_sin = Math.round(item.valor / ((100 + item.iva) / 100));
            this.$store.dispatch("setAlert", alert);
          }
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        });
    },
    download() {
      const name = "Listado-servicios-precios";
      const data = this.listaServicios.map((servicio) => {
        return {
          Codigo: servicio.codigo,
          Servicio: servicio.servicio,
          Duracion: servicio.duracion + " Min",
          Valor: servicio.valor,
          "Valor sin IVA": servicio.valor_sin,
          Iva: servicio.iva,
        };
      });
      let excel = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      const filename = name;
      XLSX.utils.book_append_sheet(workbook, excel, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
  },
  created() {
    this.loadServicios();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["recepcion", 'caja']),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    listaServicios() {
      return this.servicios.map((servicio) => {
        servicio.duracion = parseInt(servicio.duracion);
        servicio.valor = parseFloat(servicio.valor);
        servicio.iva = parseFloat(servicio.iva);
        return {
          codigo: servicio.codigo,
          duracion: servicio.duracion,
          id_activo: servicio.id_activo,
          iva: servicio.iva,
          servicio: servicio.servicio,
          valor: servicio.valor,
          valor_sin: Math.round(servicio.valor / ((100 + servicio.iva) / 100)),
        };
      });
    },
  },
};
</script>
<style scoped>
.v-text-field__details {
  display: none !important;
}
</style>
