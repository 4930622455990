var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"servicios mx-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Servicios ")]),_c('v-card-subtitle',[_vm._v(" Desde este apartado podrás modificar el valor de tus servicios. "),_c('br'),_c('strong',[_vm._v(" El IVA solo se tiene en cuenta en el régimen común ")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.listaServicios,"footer-props":{ itemsPerPageText: 'Servicios' },"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mt-6",attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":"black"},on:{"click":function($event){return _vm.download()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Descargar precios ")],1)],1)]},proxy:true},{key:"item.duracion",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.convert_minutes(item.duracion))+" ")])]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light \n                    v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined \n                    v-text-field--rounded"},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_c('fieldset',{attrs:{"aria-hidden":"true"}},[_c('legend',{staticStyle:{"width":"0px"}},[_c('span',[_vm._v("​")])])]),_c('div',{staticClass:"v-text-field__slot"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(item.valor),expression:"item.valor",modifiers:{"number":true}}],attrs:{"id":"input-386","type":"number"},domProps:{"value":(item.valor)},on:{"change":function($event){return _vm.updateItem(item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "valor", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])])])])]}},{key:"item.valor_sin",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor_sin))+" ")])]}},{key:"item.iva",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light \n                    v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined \n                    v-text-field--rounded mt-2"},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_c('fieldset',{attrs:{"aria-hidden":"true"}},[_c('legend',{staticStyle:{"width":"0px"}},[_c('span',[_vm._v("​")])])]),_c('div',{staticClass:"v-text-field__slot"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(item.iva),expression:"item.iva",modifiers:{"number":true}}],attrs:{"id":"input-386","type":"number"},domProps:{"value":(item.iva)},on:{"change":function($event){return _vm.updateItem(item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "iva", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])])])])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }